/* src/components/BookingModal.css */
.Overlay {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.Modal {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin: auto;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 90vh;
    color: #333333;
}

.Modal h2 {
    margin-top: 0;
    color: #495057;
}

.Modal form div {
    margin-bottom: 10px;
}

.Modal form label {
    display: block;
    color: #495057;
}

.Modal form input,
.Modal form select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background-color: #F8F9FA;
    color: #495057;
    border: 1px solid #CED4DA;
    border-radius: 5px;
}

.Modal form button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #6C757D;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.Modal form button:hover {
    background-color: #5A6268;
}

.Modal form button[type="button"] {
    background-color: #ADB5BD;
}

.Modal form button[type="button"]:hover {
    background-color: #868E96;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .Modal {
        padding: 15px;
    }

    .Modal form button {
        width: 100%;
        padding: 10px;
    }
}
