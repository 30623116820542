/* src/App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

main {
  padding: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

li:hover {
  background-color: #e0e0e0;
}

form div {
  margin-bottom: 10px;
}

form label {
  display: block;
}

form input {
  padding: 5px;
  width: 100%;
}

button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #21a1f1;
}
