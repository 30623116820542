/* src/components/RoomList.css */
.room-list {
    width: 100%;
    margin-top: 20px;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.room-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #F8F9FA;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.room-item:hover {
    background-color: #E9ECEF;
}

.room-item h3 {
    margin: 0;
    font-size: 18px;
    color: #495057;
}

.room-item p {
    margin: 0;
    font-size: 14px;
    color: #6C757D;
}

.room-item button {
    background-color: #6C757D;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.room-item button:hover {
    background-color: #5A6268;
}
