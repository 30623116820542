/* src/components/WeekCalendar.css */
.week-calendar {
    width: 100%;
    margin-top: 20px;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rbc-toolbar {
    background-color: #E9ECEF;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.rbc-toolbar-label {
    font-size: 18px;
    font-weight: bold;
    color: #495057;
}

.rbc-btn-group {
    margin-bottom: 10px;
}

.rbc-btn {
    background-color: #6C757D;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.rbc-btn:hover {
    background-color: #5A6268;
}

.rbc-time-view {
    font-size: 12px;
    color: #495057;
}

.rbc-event {
    background-color: #6C757D;
    color: white;
    border-radius: 5px;
    border: none;
    display: block;
    padding: 5px;
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rbc-event-label {
    display: none;
}

.rbc-current-time-indicator {
    background-color: #DC3545;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .rbc-toolbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .rbc-toolbar-label {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .rbc-btn-group {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .rbc-time-view {
        font-size: 12px;
    }

    .rbc-event {
        font-size: 10px;
    }

}
.rbc-events-container {
    margin-right: 1px!important;
}

.rbc-day-past {
    background-color: #a0a0a0; /* Серый фон для прошедших дат */
    /*color: #a0a0a0; !* Серый цвет текста для прошедших дат *!*/
    pointer-events: none; /* Отключить клики */
}

/* WeekCalendar.css */

.week-calendar {
    position: relative;
}

.week-calendar.animating {
    overflow: hidden;
}

.week-calendar.left .rbc-time-view {
    animation: slideLeft 0.3s forwards;
}

.week-calendar.right .rbc-time-view {
    animation: slideRight 0.3s forwards;
}

@keyframes slideLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
